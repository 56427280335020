export function useRequiredRule(value: string): boolean | string {
  const { $i18n } = useNuxtApp()
  return value ? true : $i18n.t('form.validation.required')
}

export function useMinLengthRule(value: string, min: number): boolean | string {
  const { $i18n } = useNuxtApp()
  return value.length >= min ? true : $i18n.t('form.validation.minChars', { min })
}

export function useMaxLengthRule(value: string, max: number): boolean | string {
  const { $i18n } = useNuxtApp()
  return value.length <= max ? true : $i18n.t('form.validation.maxChars', { max })
}

export function useMinRule(value: number, min: number): boolean | string {
  const { $i18n } = useNuxtApp()
  return value >= min ? true : $i18n.t('form.validation.minValue', { min })
}

export function useMaxRule(value: number, max: number): boolean | string {
  const { $i18n } = useNuxtApp()
  return value <= max ? true : $i18n.t('form.validation.maxValue', { max })
}

export function useEmailRule(value: string): boolean | string {
  const { $i18n } = useNuxtApp()
  const emailRegex = /^[^@\r\n\s]+[^.@]@[^.@][^@\r\n\s]+\.(\w){2,}$/i
  return emailRegex.test(value) ? true : $i18n.t('form.validation.email')
}

export function usePasswordRule(value: string): boolean | string {
  const { $i18n } = useNuxtApp()

  if (!value)
    return $i18n.t('form.validation.required')
  if (value.length < 8)
    return $i18n.t('form.validation.minChars', { min: 8 })
  if (value.length > 127)
    return $i18n.t('form.validation.maxChars', { max: 127 })
  if (!/[A-Z]/.test(value))
    return $i18n.t('form.validation.containsUppercase')
  if (!/[a-z]/.test(value))
    return $i18n.t('form.validation.containsLowercase')
  if (!/[0-9]/.test(value))
    return $i18n.t('form.validation.containsNumber')
  // If has any whitespace
  if (/\s/g.test(value))
    return $i18n.t('form.validation.containsSpace')

  return true
}

export function useNumericRule(value: string): boolean | string {
  const { $i18n } = useNuxtApp()
  return /^[0-9]*$/.test(value) ? true : $i18n.t('form.validation.numeric')
}
